/**
 * Type: ページ
 * What: スタジオ・ハリー
 */
import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import BreadcrumbComponent from '../../../components/Breadcrumb';
import SimpleSwiper from '../../../components/Slider.fade';
import Image from '../../../util/Image';
import PostListComponent from '../../../components/PostList/PostList.obm';
import { Button } from '../../../components/Btn';

const pageTitle = 'ハリーと写真が撮れる！「スタジオ・ハリー」';
const pageDescription =
  '福岡ソフトバンクホークスのマスコットキャラクター「ハリーホーク」と写真が撮れるフォトスタジオ！素敵な空間でハリーと一緒に記念撮影をしよう！';
const pageSlug = 'studio-harry';
const pageLogo = 'logo-obm.png';

const sliderDataobj = [
  {
    filename: 'main-studio_harry-top.webp',
  },
];

// Data Props Template
const Template = () => (
  <Layout pageSlug={pageSlug} bg="bg-4">
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent
      hierarchy={3}
      bread2url="obm/"
      bread2title="ヒストリーゾーン・89パーク・STUDIO E・ZO"
      bread3title={pageTitle}
    />
    <section className="pageMain bg-4 bs-4 no-border">
      <div className="kv-wrap ">
        <div className="kv-inner">
          <SimpleSwiper data={sliderDataobj} />
        </div>
        <div className="container">
          <div className="inner">
            <div className="pageMain-header">
              <div className="floorNo c-4">
                <span>4</span>
                <span>F</span>
              </div>
              <h3 className="ttl">
                <span className="logo-under">
                  <Image src={pageLogo} alt={pageTitle} />
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <PostListComponent />

    <div className="u-container u-pt-0" style={{ backgroundColor: 'white' }}>
      <div className="u-inner-840">
        <div className="u-desc">
          <p>
            「福岡ソフトバンクホークス」のマスコットキャラクター「ハリーホーク」をテーマにした12球団初の球団マスコット専用フォトスタジオです。試合日はもちろん試合が無い日でもハリーや球団マスコットがスタジオに登場し一緒にフォトジェニックな写真が撮れます！
          </p>
        </div>
        <div
          className="u-pc:col2-20 u-sp:vert-10 u-mt-30"
          style={{ justifyContent: 'center' }}
        >
          <figure>
            <Image src="studio_harry-img-1.webp" alt="" />
          </figure>
          <figure>
            <Image src="studio_harry-img-2.webp" alt="" />
          </figure>
          <figure>
            <Image src="studio_harry-img-3.webp" alt="" />
          </figure>
        </div>

        <p className="has-text-centered mt-2">
          （左：ハニーホーク　中央：バリカタ君　右：ハリーホーク）
        </p>

        <div className="u-mt-30 bg-4 u-p-20">
          {/* 価格 */}
          <div>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>料金</th>
                    <td>
                      <p>撮影券　1,000円（税込）</p>
                      <ul>
                        <li>
                          ・撮影券1枚でハリーや球団マスコットと約1分半程度撮影が可能
                        </li>
                        <li>・撮影券1枚で同伴者3名まで　合計4名まで撮影可能</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>※撮影券のみでは利用できません</p>
            <p>
              ※王貞治ベースボールミュージアムもしくは89パークへの入場券が必要です
            </p>
            <p>
              ※クラブホークス特典の対象アトラクション20%OFFクーポン使用可能です
            </p>
            <p>※6歳以下の無料入場者は人数に含みません。</p>
          </div>

          {/* 特典 */}
          <article className="u-mt-30">
            <h4 className="title is-5">特典</h4>
            <p>
              ノベルティとしてハリーホーク直筆サイン入りミニ色紙をプレゼント。
              <br />
              （撮影券1枚でノベルティ1枚のお渡し）
            </p>
            <p>
              ※ハリー以外のマスコット稼働の場合もノベルティはハリー直筆サイン入りミニ色紙となります
            </p>
          </article>

          {/* 基本営業時間 */}
          <article className="u-mt-30">
            <h4 className="title is-5">基本営業時間</h4>
            <p>
              みずほPayPayドームでのホークス試合開催によってマスコットとの撮影開催時間が異なります。
              <br />
              下記試合日程よりみずほPayPayドームで開催される試合日程をご確認ください。
            </p>
            <p className="u-mt-10 u-mb-5">
              <b>▼みずほPayPayドーム試合開催日</b>
            </p>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>18:00（18:30）試合開始</th>
                    <td>
                      <p>
                        ①13:00〜13:30
                        <br />
                        ②14:00〜14:30
                        <br />
                        ③15:00〜15:30
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>13:00試合開始</th>
                    <td>
                      <p>
                        ①10:00〜10:30
                        <br />
                        <span className="attention">
                          ②試合終了1時間後〜30分間
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>14:00試合開始</th>
                    <td>
                      <p>
                        ①10:00〜10:30
                        <br />
                        ②11:00〜11:30
                        <br />
                        <span className="attention">
                          ③試合終了1時間後〜30分間
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="u-mt-10 u-mb-5">
              <b>▼みずほPayPayドーム非試合日</b>
            </p>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>平日</th>
                    <td>
                      <p>
                        ①12:00〜12:30
                        <br />
                        ②13:00〜13:30
                        <br />
                        ③14:00〜14:30
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>土日祝</th>
                    <td>
                      <p>
                        ①12:00〜12:30
                        <br />
                        ②13:00〜13:30
                        <br />
                        ③14:00〜14:30
                        <br />
                        ④15:00〜15:30
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="list-mark">
              ※撮影開催時間は変更となる場合がございます。
            </p>
            <p className="list-mark">
              ※マスコットのスケジュール上、開催出来ない回もございます。開催有無についてはチケット販売ページでご確認ください
            </p>
            <p className="list-mark">
              <span className="attention">
                ※デーゲーム（13:00、14:00試合開始）の試合終了後の開催について
              </span>
              <br />
              ・試合終了時刻が17時を過ぎた場合は開催いたしません。
              <br />
              ・チケットは開催決定後に王貞治ベースボールミュージアムにて販売致します
              <br />
              ・開催時間は試合終了時に王貞治ベースボールミュージアムの入口にて告知いたします。
            </p>
            <div className="btn-wrap u-mt-10">
              <Button
                href="https://www.softbankhawks.co.jp/game/calendar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                試合日程はこちら
              </Button>
            </div>
            <p className="list-mark has-text-centered u-mt-10">
              ※みずほPayPayドーム以外で開催の試合も記載がございます
            </p>
          </article>

          <div className="u-mt-30">
            <h4 className="title is-5">マスコットスケジュールについて</h4>
            <p>
              マスコットのスケジュールはBOSS E・ZO
              FUKUOKA公式Xをご確認ください。
            </p>

            <div className="btn-wrap u-mt-5">
              <Button
                href="https://x.com/EZOFUKUOKA"
                target="_blank"
                rel="noopener noreferrer"
              >
                BOSS E・ZO FUKUOKA
                <br />
                公式X
              </Button>
            </div>
            <p className="u-mt-10">
              ※マスコットは予告なく変更となる場合もございます。
              <br />
              ※デーゲーム開催日　試合終了後の回は当日券のみの販売となり、開催の場合は終了後に王貞治ベースボールミュージアムにて販売致します
            </p>
          </div>

          {/* 注意事項 */}
          <article className="u-mt-30">
            <h4 className="title is-5">注意事項</h4>
            <ul>
              <li>
                ・撮影機器の貸出はございません。お客様ご自身でご用意頂きます。
              </li>
              <li>・写真の現像サービス等はございません</li>
              <li>・お客様の撮影機器で撮影をいたします</li>
              <li>
                ・スマホや一眼レフなどスタッフが操作出来る様にご用意ください
              </li>
              <li>
                ・写真撮影をするスタッフはプロではございません細かいアングルや特殊な技法など対応できかねますのでご了承ください
              </li>
            </ul>
          </article>

          <div className="btn-wrap u-mt-30">
            <Button
              href="https://ticket.e-zofukuoka.com/#/order?id=VYE3UWGTRY9J3HNC99GPJR1MFVG3QEZASHIRD90A7Z7VZVEMXN9TWX0KXAUGHH9Y"
              target="_blank"
              className="btn-buy"
              rel="noopener noreferrer"
            >
              チケット購入
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Template;
